import { Injectable } from '@angular/core';
import { NgToastService } from 'ng-angular-popup';
import { BehaviorSubject, finalize, Subscription, tap, timer } from 'rxjs';
import { SpinnerStatus } from '../enum/SpinnerStatus';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
    public isLoading = new BehaviorSubject<boolean>(false);
    private spinnerSubscription: Subscription | null = null;
  
    constructor(private toastService: NgToastService) {}
  
    setLoading(message: string, status: SpinnerStatus) {
  
      if (this.spinnerSubscription) {
        this.spinnerSubscription.unsubscribe();
        this.spinnerSubscription = null;
      }
  
      this.isLoading.next(true);
  
      this.spinnerSubscription = timer(1500)
        .pipe(
          tap(() => {
            if (status === SpinnerStatus.SUCCESS) {
              this.toastService.success({ detail: message });
            } else {
              this.toastService.error({ detail: message });
            }
          }),
          finalize(() => {
            this.isLoading.next(false);
          })
        )
        .subscribe();
    }

  protected readonly SpinnerStatus=SpinnerStatus
}