import {Injectable} from '@angular/core';
import {
    HttpClient,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import {catchError, Observable, switchMap, throwError} from 'rxjs';
import {AuthService} from 'app/core/auth/auth.service';
import {AuthUtils} from "./auth.utils";
import {JwtHelperService} from "@auth0/angular-jwt";
import {environment} from "../../../environments/environment";
import {TranslocoService} from "@ngneat/transloco";
import { User } from '../../shared/models/auth/user/user.types';
import {Router} from "@angular/router";


const REFRESH_URL = `${environment.authUrl}/refresh`;
const API_DOTNET_CRM_URL = 'http://192.168.1.165:8081/api';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    currentUser: User = new User();

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthService,
        private _translocoService:TranslocoService,

) {
}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        const isCRMDotNetAlternateServer = req.url.startsWith(API_DOTNET_CRM_URL);
        let modifiedReq = req;

        //if(this.currentUser.uuid != null &&
           // this.currentUser.sessionUuid != localStorage.getItem("sessionUuid")){
           // localStorage.clear()
           // window.location.reload()
        //}
        //else
        if(!isCRMDotNetAlternateServer){
            if (
                AuthUtils.isTokenExpired(this._authService.accessToken) &&
                this._authService.refresh === '1' &&
                this._authService.refreshToken
            ) {
                return this.handleTokenRefresh(req, next);
            } else if (req.url === REFRESH_URL && this._authService.refreshToken) {
                return next.handle(req.clone({}));
            } else {
                const newReq = req.clone({
                    headers: req.headers.set(
                        'Authorization',
                        'Bearer ' + this._authService.accessToken
                    ).set('Accept-Language', this._translocoService.getActiveLang())
                });
                this._authService.refresh = '1';
                return next.handle(newReq).pipe(
                    catchError((error) => this.handleErrorResponse(error))
                );
            }
    }else{
        const CRMVoyageursToken = this._authService.getCRMDotNetToken();

        modifiedReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + CRMVoyageursToken)
        });
    }

    return next.handle(modifiedReq).pipe(
        catchError((error) => this.handleErrorResponse(error))
    );

    }

    private handleTokenRefresh(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const payload = {
            refreshToken: this._authService.refreshToken
        };
        this._authService.refresh = '0';

        return this._httpClient.post<any>(REFRESH_URL, payload).pipe(
            switchMap((resp: any) => {
                this._authService.accessToken = resp.access_token;
                this._authService.refreshToken = resp.refresh_token;


                const newReq = req.clone({
                    headers: req.headers.set('Authorization', resp.access_token).set('Accept-Language', this._translocoService.getActiveLang()),

            });

                const jwtHelper = new JwtHelperService();
                const objJWT = jwtHelper.decodeToken(resp.access_token);

                const payloadTokenClaims = {
                    uuid: objJWT.access_id,
                    role: objJWT.authorities
                };

                localStorage.setItem('useruuid', objJWT.access_id);
                localStorage.setItem('role', objJWT.authorities);

                return next.handle(newReq);
            }),catchError((error) => {
                localStorage.clear()
                window.location.reload();
                return this.handleErrorResponse(error)
            })
        );
    }

    private handleErrorResponse(error: any): Observable<never> {
        if (
            error.error === 'Invalid refresh token' &&
            error.status === 401
        ) {
            this._authService.signOut();
        }
        return throwError(error);
    }
}
