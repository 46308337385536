/* tslint:disable:max-line-length */
import {FuseNavigationItem} from '@fuse/components/navigation';
export const childrensNavigation: FuseNavigationItem[] = [
]
export const parentsNavigation: FuseNavigationItem[] = [

]

export const defaultPartnerNavigation: FuseNavigationItem[] = [

    {
        id: 'donnees_entreprise_menu',
        title: 'Données Entreprise',
        type: 'basic',
        icon: 'heroicons_outline:newspaper',
        link: '/admin/Entreprise'
    },
    {
        id: 'gestion_employes_menu',
        title: 'Gestion employés',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: 'admin/collaborateur'
    },
    {
        id: 'cra_menu',
        title: 'CRA',
        type: 'collapsable',
        icon: 'heroicons_outline:newspaper',
        children:[
            {
                id: 'cra_liste',
                title: 'Liste Des Cra',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-list',
                link: 'admin/cra',
            }
        ]
    },
    {
        id: 'upgrade_menu',
        title: 'Upgrade',
        type: 'basic',
        icon: 'heroicons_outline:sparkles',
        link: 'admin/upgrade'
    },
    {


        id   : 'absence_pointage_menu',
        title: 'Absence & Pointage',
        type: 'collapsable',
        icon: 'heroicons_outline:clock',
        children: [
            {
                id: 'demandes_conge_submenu',
                title: 'Demandes de congé',
                type: 'basic',
                icon: 'heroicons_outline:annotation',
                link: 'admin/leave/leave-list'
            },
            {
                id: 'absence_submenu',
                title: 'Absence',
                type: 'basic',
                icon: 'heroicons_outline:annotation',
                link: 'admin/leave/leave-absence'
            },
            {
                id: 'solde_conges_submenu',
                title: 'Solde congés',
                type: 'basic',
                icon: 'heroicons_outline:calendar',
                link: 'admin/leave/leave-solde'

            },
            {
                id: 'pointage_submenu',
                title: 'Pointage',
                type: 'basic',
                icon: 'heroicons_outline:finger-print',
                link: 'admin/leave/pointage'
            },
            {
                id: 'validation_absence_submenu',
                title: 'Validation',
                type: 'basic',
                icon: 'heroicons_outline:badge-check',
                link: 'admin/leave/validation'
            },
        ]
    },
    {
        id   : 'paie_menu',
        title: 'Paie',
        type: 'collapsable',
        icon: 'heroicons_outline:cash',
        children: [
            {
                id: 'fiche_paie_validation_sub_menu',
                title: 'Validation',
                type: 'basic',
                icon: 'heroicons_outline:badge-check',
                link: 'admin/payroll/validation'
            },
            {
                id: 'fiche_paie_sub_menu',
                title: 'Fiches de paie',
                type: 'basic',
                icon: 'heroicons_outline:document-text',
                link: 'admin/payroll/fiches-paie'
            },
            {
                id: 'variable_paie_submenu',
                title: 'Variable de paie EVP',
                type: 'basic',
                icon: 'heroicons_outline:currency-dollar',
                link: 'admin/payroll/variablePaie'
            },
            {
                id: 'renumeration_submenu',
                title: 'Renumeration',
                type: 'basic',
                icon: 'poll',
                link: 'admin/payroll/renumeration'
            },
            {
                id: 'virement_auto_submenu',
                title: 'Virements Automatique',
                type: 'basic',
                icon: 'heroicons_outline:currency-dollar',
                link: 'admin/payroll/virement-auto'
            }
        ]
    },
    {
        id: 'recrutment_menu',
        title: 'Recrutement',
        type: 'collapsable',
        icon: 'business_center',
        children: [
           {
                id   : 'statistiques_recrutement_submenu',
                title: 'Statistiques',
                type: 'basic',
                icon: 'heroicons_solid:presentation-chart-bar',
                link: 'admin/recrutement/statistique'
            },
            {
                id: 'demandes_recrutement_submenu',
                title: 'Demandes',
                type: 'basic',
                icon: 'mark_email_unread',
                link: 'admin/recrutement/Demandes'
            },
            {
                id   : 'recrutment_submenu',
                title: 'Recrutement',
                type: 'basic',
                icon: 'hail',
                link: 'admin/recrutement/recrutement'
            },
            {

                id   : 'agents_recrutment_submenu',
                title: 'Agents',
                type: 'basic',
                icon: 'mat_outline:badge',
                link: 'admin/recrutement/agents'
            }
        ]
    },
    {
        id      : 'facturation_menu',
        title   : 'Facturation',
        type    : 'collapsable',
        icon    : 'heroicons_outline:clipboard-list',
        children: [
            {
                id: 'contact_facturation_submenu',
                title: 'Contact',
                type: 'basic',
                icon: 'people_alt',
                link: 'admin/invoice/contact'
            },

            {
                id: 'achat_facturation_submenu',
                title: 'Achat',
                type: 'basic',
                icon: 'heroicons_outline:shopping-cart',
                link: '/admin/invoice/achatventcommon/achat'

            },
            {
                id: 'vente_facturation_submenu',
                title: 'Vente',
                type: 'basic',
                icon: 'heroicons_outline:tag',
                link: '/admin/invoice/achatventcommon/vente'

            }

        ]
    },
    {
        id      : 'caisse_menu',
        title   : 'Caisse',
        type    : 'collapsable',
        icon    : 'heroicons_outline:currency-dollar',
        children: [
            {
                id: 'etat_caisse_submenu',
                title: 'Caisse',
                type: 'basic',
                icon: 'heroicons_outline:currency-dollar',
                link: 'admin/caisse/list-caisse'

            },
            {
                id: 'transactions_caisse_submenu',
                title: 'Transactions',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: 'admin/caisse/transactions'
            },
            {
                id: 'virement_caisse_submenu',
                title: 'Virements',
                type: 'basic',
                icon: 'heroicons_outline:library',
                link: 'admin/caisse/virements'

            },
            {
                id: 'comptes_bancaires_submenu',
                title: 'Comptes bancaires',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: 'admin/caisse/compte-bancaire'
            }, {
                id: 'suivi_cheque_caisse_submenu',
                title: 'Suivi Chèque',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: 'admin/caisse/suivi-cheque'
            }, {
                id: 'suivi_virement_caisse_submenu',
                title: 'Suivi Virement',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: 'admin/caisse/suivi-virement'
            },
            , {
                id: 'suivi_credit_steg_caisse_submenu',
                title: 'Suivi Credit STEG',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: 'admin/caisse/suivi-credit-steg'
            },
            , {
                id: 'suivi_subvention_submenu',
                title: 'Suivi Subvention',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: 'admin/caisse/suivi-subvention'
            }
        ]
    },
    {
        id      : 'stock_menu',
        title   : 'Stock',
        type    : 'collapsable',
        icon    : 'heroicons_outline:clipboard-check',
        children: [
            {
                id: 'dashbord_stock',
                title: 'Dashboard',
                type: 'basic',
                icon: 'people_alt',
                link: 'admin/stock/dashboard'

            },
            {

                id      : 'gestion_depot_stock',
                title   : 'Gestion Dépôt',
                type    : 'collapsable',
                icon    : 'heroicons_outline:truck',
                children: [

                    {
                        id   : 'depot_stock',
                        title: 'Dépôt',
                        type : 'basic',
                        icon: 'store',
                        link : 'admin/stock/stock',
                    },
                    {
                        id   : 'suivi_depot',
                        title: 'Suivi Dépôt',
                        icon: 'feather:pie-chart',
                        type : 'basic',
                        link : 'admin/stock/suiviStock',
                    },
                    {
                        id   : 'transfere_depot_stock',
                        title: 'demandesTransfere',
                        icon: 'precision_manufacturing',
                        type : 'basic',
                        link : 'admin/stock/demandesTransfere',
                    }
                ]
            },
            {

                id: 'gestion_alerte_stock',
                title: 'Gestion Alertes',
                type: 'collapsable',
                icon: 'heroicons_outline:exclamation',
                children: [
                    {
                        id: 'alerte_submenu_stock',
                        title: 'Alerte',
                        icon: 'add_alert',
                        type: 'basic',
                        link: 'admin/stock/alerte'
                    },
                    {
                        id: 'etat_stock_submenu_stock',
                        title: 'Etat stock',
                        icon: 'insert_chart_outlined',
                        type: 'basic',
                        link: 'admin/stock/Listalert'
                    },
                ]

            },
            {

                id: 'product_menu',
                title: 'Produits',
                type: 'collapsable',
                icon: 'heroicons_outline:shopping-cart',
                children: [
                    {
                        id: 'category_sub_menu',
                        title: 'Catégorie',
                        type: 'basic',
                        link: 'admin/stock/category'
                    },
                    {
                        id: 'product_sub_menu',
                        title: 'list_Produits',
                        type: 'basic',
                        link: 'admin/stock/product'
                    },
                ]

            },

            {
                id      : 'appro_submenu',
                title   : 'Approvisionnement',
                type    : 'collapsable',
                icon    : 'heroicons_outline:clipboard-list',
                children: [
                    {
                        id: 'contact_submenu',
                        title: 'Contact',
                        type: 'basic',
                        icon: 'people_alt',
                        link: 'admin/stock/contact'

                    },
                    {
                        id: 'achat_submenu',
                        title: 'Achat',
                        type: 'basic',
                        icon: 'heroicons_outline:shopping-cart',
                        link: 'admin/stock/achatventcommon/achat'
                    },
                    //                     {
                    //     id: 'VenteComponent',
                    //     title: 'Alerte',
                    //     type: 'basic',
                    //     icon: 'heroicons_outline:tag',
                    //     link: 'admin/stock/alerte'
                    // }

                ]
            },
            {
                id: 'vente_menu',
                title: 'Vente',
                type: 'collapsable',
                icon: 'heroicons_outline:tag',
                children: [
                    {
                        id: 'vente_submenu',
                        title: 'Vente',
                        type: 'basic',
                        icon: 'heroicons_outline:tag',
                        link: 'admin/stock/achatventcommon/vente'

                    },
                    {
                        id: 'etat_tier_submenu',
                        title: 'Etat tiers',
                        type: 'basic',
                        icon: 'heroicons_outline:shopping-cart',
                        link: 'admin/stock/achatventcommon/etat-tiers'
                    },

                ]

            },
            {
                id: 'avoir_sub_menu',
                title: 'Avoir',
                type: 'basic',
                icon: 'mat_solid:payment',
                link: 'admin/stock/listAvoir'
            },
            {
                id: 'inventory_sub_menu',
                title: 'Inventory',
                type: 'basic',
                icon: 'mat_solid:payment',
                link: 'admin/stock/listInventory'
            },
            {

                id: 'commercial_sub_menu',
                title: 'Commercial',
                type: 'basic',
                icon: 'mat_solid:payment',
                link: 'admin/stock/commercial'
            },
            {
                id: 'retours_submenu',
                title: 'Retours',
                type: 'basic',
                icon: 'mat_solid:payment',
                link: 'admin/stock/retours'
            },
            {

                id: 'demande_approvement_submenu',
                title: 'Demandes de Prélèvement',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-list',
                link: '/admin/stock/achatventcommon/demandes'
            },


        ]
    },
    {
        id: 'gestion_tickets_menu',
         title: 'Gestion de ticket',
         type: 'collapsable',
         icon: 'heroicons_outline:pencil-alt',
         children: [
            {

                id   : 'statiqtiques_ticket_submenu',
                title: 'Statistiques',
                type: 'basic',
                icon: 'feather:pie-chart',
                link: 'admin/gestion-ticket/statistiqueticket'
            },
            {
                 id   : 'ticket_submenu',
                 title: 'Ticket',
                 type: 'basic',
                 icon: 'heroicons_solid:shield-check',
                 link: 'admin/gestion-ticket/gestionticket'
             },
         ]
    },
    {

        id: 'configuration_menu',
        title: 'Configuration',
        type: 'basic',
        icon: 'heroicons_outline:adjustments',
        link: 'admin/setting'
    },
    //NEWCLIENT
    // {
    //     id      : 'FacturationComponent2',
    //     title   : 'Facturation',
    //     type    : 'collapsable',
    //     icon    : 'heroicons_outline:clipboard-list',
    //     children: [
    //
    //         {
    //             id   : 'ContactComponent2',
    //             title: 'Contact',
    //             type : 'basic',
    //             icon : 'people_alt',
    //             link : 'admin/invoice-client/client'
    //         },
    //
    //         {
    //             id   : 'FacturationVenteComponent2',
    //             title: 'Vente',
    //             type : 'basic',
    //             icon : 'heroicons_outline:tag',
    //             link : 'admin/invoice-client/list-facture'
    //         },
    //         {
    //             id   : 'TransactionsComponent2',
    //             title: 'Transactions',
    //             type : 'basic',
    //             icon : 'heroicons_outline:currency-dollar',
    //             link : 'admin/invoice-client/transactions'
    //         },
    //     ]
    //
    // },
    // {
    //     id      : 'CaisseComponent2',
    //     title   : 'Caisse',
    //     type    : 'collapsable',
    //     icon    : 'heroicons_outline:currency-dollar',
    //     children: [
    //         {
    //             id   : 'Caisse-ClientComponent2',
    //             title: 'Caisse',
    //             type : 'basic',
    //             icon : 'heroicons_outline:calculator',
    //             link : 'admin/fund-client/caisse-client'
    //         },
    //         {
    //             id   : 'Compte-Client2',
    //             title: 'Comptes bancaires',
    //             type : 'basic',
    //             icon : 'heroicons_outline:cash',
    //             link : 'admin/fund-client/compte-bancaire-client'
    //         },
    //         {
    //             id   : 'TransactionsCaisseComponent2',
    //             title: 'Transactions',
    //             type : 'basic',
    //             icon : 'heroicons_outline:currency-dollar',
    //             link : 'admin/fund-client/transaction-tiers-collab'
    //         },
    //     ]
    // }
];

export const defaultPartnerNavigation2: FuseNavigationItem[] = [

    {
        id: 'DonneesEntrepriseComponent',
        title: 'Donnees Entreprise',
        type: 'basic',
        icon: 'heroicons_outline:newspaper',
        link: '/admin/Entreprise'
    },
    {
        id: 'CollaboratorComponent',
        title: 'Gestion employé',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: 'admin/collaborateur'
    },
    {
        id: 'cra_menu',
        title: 'CRA',
        type: 'collapsable',
        icon: 'heroicons_outline:newspaper',
        children:[
            {
                id: 'cra_liste',
                title: 'Liste Des Cra',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-list',
                link: 'admin/cra',
            }
        ]
    },



    {
        id: 'upgrade_menu',
        title: 'Upgrade',
        type: 'basic',
        icon: 'heroicons_outline:sparkles',
        link: 'admin/upgrade'
    },
    {


        id   : 'DashLeaveComponent',
        title: 'Absence & Pointage',
        type: 'collapsable',
        icon: 'heroicons_outline:clock',
        children: [
            {
                id: 'LeaveListComponent',
                title: 'Demande de congés',
                type: 'basic',
                icon: 'heroicons_outline:annotation',
                link: 'admin/leave/leave-list'
            },
            {
                id: 'LeaveAbsenceComponent',
                title: 'Absence',
                type: 'basic',
                icon: 'heroicons_outline:annotation',
                link: 'admin/leave/leave-absence'
            },
            {
                id: 'LeaveSoldeComponent',
                title: 'Solde congé',
                type: 'basic',
                icon: 'heroicons_outline:calendar',
                link: 'admin/leave/leave-solde'

            },
            {
                id: 'PointageComponent',
                title: 'Pointage',
                type: 'basic',
                icon: 'heroicons_outline:finger-print',
                link: 'admin/leave/pointage'
            },
            {
                id: 'FichePaieComponent',
                title: 'Validation',
                type: 'basic',
                icon: 'heroicons_outline:badge-check',
                link: 'admin/leave/validation'
            },
        ]
    },
    {
        id   : 'DashPayrollComponent',
        title: 'Paie',
        type: 'collapsable',
        icon: 'heroicons_outline:cash',
        children: [
            {
                id: 'FichePaieComponent',
                title: 'Validation',
                type: 'basic',
                icon: 'heroicons_outline:badge-check',
                link: 'admin/payroll/validation'
            },
            {
                id: 'FichePaieComponent',
                title: 'Fiches de paie',
                type: 'basic',
                icon: 'heroicons_outline:document-text',
                link: 'admin/payroll/fiches-paie'
            },
            {
                id: 'PayrollVariableEVPComponent',
                title: 'Variable de paie EVP',
                type: 'basic',
                icon: 'heroicons_outline:currency-dollar',
                link: 'admin/payroll/variablePaie'
            },
            {
                id: 'RenumerationComponent',
                title: 'Renumeration',
                type: 'basic',
                icon: 'poll',
                link: 'admin/payroll/renumeration'
            },
            {
                id: 'PayrollVirementsAutoComponent',
                title: 'Virements Automatique',
                type: 'basic',
                icon: 'heroicons_outline:currency-dollar',
                link: 'admin/payroll/virement-auto'
            }
        ]
    },
    {
        id: 'Recrutement',
        title: 'Recrutement',
        type: 'collapsable',
        icon: 'business_center',
        children: [
           {
                id   : 'StatistiqueComponent',
                title: 'Statistiques',
                type: 'basic',
                icon: 'heroicons_solid:presentation-chart-bar',
                link: 'admin/recrutement/statistique'
            },
            {
                id: 'DemandeComponent',
                title: 'Demandes',
                type: 'basic',
                icon: 'mark_email_unread',
                link: 'admin/recrutement/Demandes'
            },
            {
                id   : 'RecrutementDemandeComponent',
                title: 'Recrutement',
                type: 'basic',
                icon: 'hail',
                link: 'admin/recrutement/recrutement'
            },
            {

                id   : 'AgentsComponent',
                title: 'Agents',
                type: 'basic',
                icon: 'mat_outline:badge',
                link: 'admin/recrutement/agents'
            }
        ]
    },
    {
        id      : 'FacturationComponent',
        title   : 'Facturation',
        type    : 'collapsable',
        icon    : 'heroicons_outline:clipboard-list',
        children: [
            {
                id: 'ContactComponent',
                title: 'Contact',
                type: 'basic',
                icon: 'people_alt',
                link: 'admin/invoice/contact'
            },

            {
                id: 'AchatComponent',
                title: 'Achat',
                type: 'basic',
                icon: 'heroicons_outline:shopping-cart',
                link: '/admin/invoice/achatventcommon/achat'

            },
            {
                id: 'VenteComponent',
                title: 'Vente',
                type: 'basic',
                icon: 'heroicons_outline:tag',
                link: '/admin/invoice/achatventcommon/vente'

            }

        ]
    },
    {
        id      : 'CaisseComponent',
        title   : 'Caisse',
        type    : 'collapsable',
        icon    : 'heroicons_outline:currency-dollar',
        children: [
            {
                id: 'EtatGeneralCaisseComponent',
                title: 'Caisse',
                type: 'basic',
                icon: 'heroicons_outline:currency-dollar',
                link: 'admin/caisse/list-caisse'

            },
            {
                id: 'TransactionsComponent',
                title: 'Transactions',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: 'admin/caisse/transactions'
            },
            {
                id: 'VirementComponent',
                title: 'Virements',
                type: 'basic',
                icon: 'heroicons_outline:library',
                link: 'admin/caisse/virements'

            },
            {
                id: 'ComptesBancairesComponent',
                title: 'Comptes bancaires',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: 'admin/caisse/compte-bancaire'
            }, {
                id: 'SuiviChequeComponent',
                title: 'Suivi Chèque',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: 'admin/caisse/suivi-cheque'
            }, {
                id: 'SuiviVirementComponent',
                title: 'Suivi Virement',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: 'admin/caisse/suivi-virement'
            }
        ]
    },
    {
        id      : 'StockComponent',
        title   : 'Stock',
        type    : 'collapsable',
        icon    : 'heroicons_outline:clipboard-check',
        children: [
            {
                id: 'Tableau de bord',
                title: 'Dashboard',
                type: 'basic',
                icon: 'people_alt',
                link: 'admin/stock/dashboard'

            },
            {

                id      : 'Gestion Dépôt',
                title   : 'Gestion Dépôt',
                type    : 'collapsable',
                icon    : 'heroicons_outline:truck',
                children: [

                    {
                        id   : 'Dépôt',
                        title: 'Dépôt',
                        type : 'basic',
                        icon: 'store',
                        link : 'admin/stock/stock',
                    },
                    {
                        id   : 'apps.stock.suivi',
                        title: 'Suivi Dépôt',
                        icon: 'feather:pie-chart',
                        type : 'basic',
                        link : 'admin/stock/suiviStock',
                    },
                    {
                        id   : 'DemandeTransfereComponent',
                        title: 'demandesTransfere',
                        icon: 'precision_manufacturing',
                        type : 'basic',
                        link : 'admin/stock/demandesTransfere',
                    }
                ]
            },
            {
                id: 'Gestion Alertes',
                title: 'Gestion Alertes',
                type: 'collapsable',
                icon: 'heroicons_outline:exclamation',
                children: [
                    {
                        id: 'Alerte',
                        title: 'Alerte',
                        icon: 'add_alert',
                        type: 'basic',
                        link: 'admin/stock/alerte'
                    },
                    {
                        id: 'Etat stock',
                        title: 'Etat stock',
                        icon: 'insert_chart_outlined',
                        type: 'basic',
                        link: 'admin/stock/Listalert'
                    },
                ]

            },
            {
                id: 'ProductComponent',
                title: 'Produits',
                type: 'collapsable',
                icon: 'heroicons_outline:shopping-cart',
                children: [

                    {
                        id: 'StockProductComponent',
                        title: 'Produits',
                        type: 'basic',
                        link: 'admin/stock/product'
                    },
                ]

            },

            {
                id      : 'FacturationComponent',
                title   : 'Approvisionnement',
                type    : 'collapsable',
                icon    : 'heroicons_outline:clipboard-list',
                children: [
                    {
                        id: 'ContactComponent',
                        title: 'Contact',
                        type: 'basic',
                        icon: 'people_alt',
                        link: 'admin/stock/contact'

                    },
                    {
                        id: 'AchatComponent',
                        title: 'Achat',
                        type: 'basic',
                        icon: 'heroicons_outline:shopping-cart',
                        link: 'admin/stock/achatventcommon/achat'
                    },


                ]
            },
            {
                id: 'VenteComponent',
                title: 'Vente',
                type: 'collapsable',
                icon: 'heroicons_outline:tag',
                children: [
                    {
                        id: 'VenteComponent',
                        title: 'Vente',
                        type: 'basic',
                        icon: 'heroicons_outline:tag',
                        link: 'admin/stock/achatventcommon/vente'

                    },
                    {
                        id: 'EtatTiersComponent',
                        title: 'Etat tiers',
                        type: 'basic',
                        icon: 'heroicons_outline:check-circle',
                        link: 'admin/stock/achatventcommon/etat-tiers'
                    },
                    {
                        id: 'FacturesComponent',
                        title: 'Factures',
                        type: 'basic',
                        icon: 'heroicons_outline:check-circle',
                        link: 'admin/stock/achatventcommon/factures'
                    },

                ]

            },
            {
                id: 'avoirComponent',
                title: 'Avoir',
                type: 'basic',
                icon: 'mat_solid:payment',
                link: 'admin/stock/listAvoir'
            },
            {
                id: 'inventoryComponent',
                title: 'Inventory',
                type: 'basic',
                icon: 'mat_solid:payment',
                link: 'admin/stock/listInventory'
            },
            {
                id: 'commercial_sub_menu',
                title: 'Commercial',
                type: 'basic',
                icon: 'mat_solid:payment',
                link: 'admin/stock/commercial'
            },
            {
                id: 'retours_submenu',
                title: 'Retours',
                type: 'basic',
                icon: 'mat_solid:payment',
                link: 'admin/stock/retours'
            }


        ]
    },
    {
        id: 'GestionTicketComponent',
         title: 'Gestion de ticket',
         type: 'collapsable',
         icon: 'heroicons_outline:pencil-alt',
         children: [
            {

                id   : 'StatistiqueTicketComponent',
                title: 'Statistiques',
                type: 'basic',
                icon: 'feather:pie-chart',
                link: 'admin/gestion-ticket/statistiqueticket'
            },
            {
                 id   : 'GestionTicketComponent2',
                 title: 'Ticket',
                 type: 'basic',
                 icon: 'heroicons_solid:shield-check',
                 link: 'admin/gestion-ticket/gestionticket'
             },
         ]
    },
    {

        id: 'configuration_menu',
        title: 'Configuration',
        type: 'basic',
        icon: 'heroicons_outline:adjustments',
        link: 'admin/setting'
    },

];

export const customNavigation: FuseNavigationItem[] = [

    {
        id: 'DonneesEntrepriseComponent',
        title: 'Donnees Entreprise',
        type: 'basic',
        icon: 'heroicons_outline:newspaper',
        link: '/admin/Entreprise'
    },
    {
        id: 'CollaboratorComponent',
        title: 'Gestion employé',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: 'admin/collaborateur'
    },
    {
        id: 'cra_menu',
        title: 'CRA',
        type: 'collapsable',
        icon: 'heroicons_outline:newspaper',
        children:[
            {
                id: 'cra_liste',
                title: 'Liste Des Cra',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-list',
                link: 'admin/cra',
            }
        ]
    },

    {
        id: 'upgrade_menu',
        title: 'Upgrade',
        type: 'basic',
        icon: 'heroicons_outline:sparkles',
        link: 'admin/upgrade'
    },
    {
        id      : 'CaisseComponent',
        title   : 'Caisse',
        type    : 'collapsable',
        icon    : 'heroicons_outline:currency-dollar',
        children: [
            {
                id: 'EtatGeneralCaisseComponent',
                title: 'Caisse',
                type: 'basic',
                icon: 'heroicons_outline:currency-dollar',
                link: 'admin/caisse/list-caisse'

            },
            {
                id: 'TransactionsComponent',
                title: 'Transactions',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: 'admin/caisse/transactions'
            },
            {
                id: 'VirementComponent',
                title: 'Virements',
                type: 'basic',
                icon: 'heroicons_outline:library',
                link: 'admin/caisse/virements'

            },
            {
                id: 'ComptesBancairesComponent',
                title: 'Comptes bancaires',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: 'admin/caisse/compte-bancaire'
            }
        ]
    },
    {
        id      : 'StockComponent',
        title   : 'Stock',
        type    : 'collapsable',
        icon    : 'heroicons_outline:clipboard-check',
        children: [
            {
                id: 'Tableau de bord',
                title: 'Dashboard',
                type: 'basic',
                icon: 'people_alt',
                link: 'admin/stock/dashboard'

            },
            {

                id      : 'Gestion Dépôt',
                title   : 'Gestion Dépôt',
                type    : 'collapsable',
                icon    : 'heroicons_outline:truck',
                children: [

                    {
                        id   : 'Dépôt',
                        title: 'Dépôt',
                        type : 'basic',
                        link : 'admin/stock/stock',
                    },
                    {
                        id   : 'apps.stock.suivi',
                        title: 'Suivi Dépôt',
                        type : 'basic',
                        link : 'admin/stock/suiviStock',
                    },
                    {
                        id   : 'DemandeTransfereComponent',
                        title: 'demandesTransfere',
                        icon: 'precision_manufacturing',
                        type : 'basic',
                        link : 'admin/stock/demandesTransfere',
                    }
                ]
            },
            {
                id: 'Gestion Alertes',
                title: 'Gestion Alertes',
                type: 'collapsable',
                icon: 'heroicons_outline:exclamation',
                children: [
                    {
                        id: 'Alerte',
                        title: 'Alerte',
                        icon: 'add_alert',
                        type: 'basic',
                        link: 'admin/stock/alerte'
                    },
                    {
                        id: 'Etat stock',
                        title: 'Etat stock',
                        icon: 'insert_chart_outlined',
                        type: 'basic',
                        link: 'admin/stock/Listalert'
                    },
                ]

            },
            {
                id: 'ProductComponent',
                title: 'Produits',
                type: 'collapsable',
                icon: 'heroicons_outline:shopping-cart',
                children: [
                    {
                        id: 'CategoryComponent',
                        title: 'Catégorie',
                        type: 'basic',
                        link: 'admin/stock/category'
                    },
                    {
                        id: 'StockProductComponent',
                        title: 'Produits',
                        type: 'basic',
                        link: 'admin/stock/product'
                    },
                ]

            },

            {
                id      : 'FacturationComponent',
                title   : 'Approvisionnement',
                type    : 'collapsable',
                icon    : 'heroicons_outline:clipboard-list',
                children: [
                    {
                        id: 'ContactComponent',
                        title: 'Contact',
                        type: 'basic',
                        icon: 'people_alt',
                        link: 'admin/stock/contact'

                    },
                    {
                        id: 'AchatComponent',
                        title: 'Achat',
                        type: 'basic',
                        icon: 'heroicons_outline:shopping-cart',
                        link: 'admin/stock/achatventcommon/achat'
                    },


                ]
            },
            {
                id: 'VenteComponent',
                title: 'Vente',
                type: 'collapsable',
                icon: 'heroicons_outline:tag',
                children: [
                    {
                        id: 'VenteComponent',
                        title: 'Vente',
                        type: 'basic',
                        icon: 'heroicons_outline:tag',
                        link: 'admin/stock/achatventcommon/vente'

                    },
                    {
                        id: 'EtatTiersComponent',
                        title: 'Etat tiers',
                        type: 'basic',
                        icon: 'heroicons_outline:check-circle',
                        link: 'admin/stock/achatventcommon/etat-tiers'
                    },
                    {
                        id: 'FacturesComponent',
                        title: 'Factures',
                        type: 'basic',
                        icon: 'heroicons_outline:check-circle',
                        link: 'admin/stock/achatventcommon/factures'
                    },

                ]

            },
            {
                id: 'avoirComponent',
                title: 'Avoir',
                type: 'basic',
                icon: 'mat_solid:payment',
                link: 'admin/stock/listAvoir'
            },
            {
                id: 'inventoryComponent',
                title: 'Inventory',
                type: 'basic',
                icon: 'mat_solid:payment',
                link: 'admin/stock/listInventory'
            },
            {
                id: 'commercial_sub_menu',
                title: 'Commercial',
                type: 'basic',
                icon: 'mat_solid:payment',
                link: 'admin/stock/commercial'
            },
            {
                id: 'retours_submenu',
                title: 'Retours',
                type: 'basic',
                icon: 'mat_solid:payment',
                link: 'admin/stock/retours'
            }

        ]
    },
    {
        id: 'GestionTicketComponent',
         title: 'Gestion de ticket',
         type: 'collapsable',
         icon: 'heroicons_outline:pencil-alt',
         children: [
            {

                id   : 'StatistiqueTicketComponent',
                title: 'Statistiques',
                type: 'basic',
                icon: 'feather:pie-chart',
                link: 'admin/gestion-ticket/statistiqueticket'
            },
            {
                 id   : 'GestionTicketComponent2',
                 title: 'Ticket',
                 type: 'basic',
                 icon: 'heroicons_solid:shield-check',
                 link: 'admin/gestion-ticket/gestionticket'
             },
         ]
    },
    {

        id: 'GeneralSettingComponent',
        title: 'Configuration',
        type: 'basic',
        icon: 'heroicons_outline:adjustments',
        link: 'admin/setting'
    },

];
export const defaultEmployeeNavigation: FuseNavigationItem[] = [
    {
        id: 'DonneesEmployeComponent',
        title: 'Donnees Employé',
        type: 'basic',
        icon: 'heroicons_outline:newspaper',
        link: 'employee/profile'
    },
    {
        id: 'cra_menu',
        title: 'CRA',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-document-list',
        link: 'collaborateur/cra'
    },
    {
        id: 'upgrade_menu',
        title: 'Upgrade',
        type: 'basic',
        icon: 'heroicons_outline:sparkles',
        link: 'admin/upgrade'
    },
    {
        id   : 'DashLeaveComponent',
        title: 'Absence & Pointage',
        type: 'collapsable',
        icon: 'heroicons_outline:clock',
        children: [
            {
                id: 'AbsenceComponent',
                title: 'Lancer une demande',
                type: 'basic',
                icon: 'mat_outline:wysiwyg',
                link: '/employee/leave/absence'
            },
            {
                id: 'AbsenceDemandeChefComponent',
                title: 'Demande de congés',
                type: 'basic',
                icon: 'mat_outline:text_snippet',
                link: '/employee/leave/Liste demande'
            }
        ]
    },
    //NEWCLIENT
    {
        id   : 'Paie',
        title: 'Paie',
        type : 'collapsable',
        icon : 'heroicons_outline:cash',
        children: [
            {
                id   : 'AbsenceComponent',
                title: 'Solde RTT',
                type : 'basic',
                icon : 'heroicons_outline:calendar',
                link : '/employee/list rtt'
            },
        ]
    },
    {
        id: 'Recrutement',
        title: 'Recrutement',
        type: 'collapsable',
        icon: 'business_center',
        children: [
            {
                id: 'DemandeComponentEmployee',
                title: 'Demandes',
                type: 'basic',
                icon: 'mark_email_unread',
                link: 'employee/recrutement/demandes'
            }
        ]
    },
    {
        id      : 'FacturationComponent2',
        title   : 'Facturation',
        type    : 'collapsable',
        icon    : 'heroicons_outline:clipboard-list',
        children: [


            {
                id: 'FacturationVenteComponentEmployee',
                title: 'Vente',
                type: 'basic',
                icon: 'heroicons_outline:tag',
                link: '/employee/invoice-client-employee/list-facture'
            }


        ]
    },
    {
        id   : 'GestionTicket',
        title: 'Gestion Ticket',
        type : 'basic',
        icon : 'heroicons_outline:pencil-alt',
        link : '/employee/ticketEmployee'
    },
    {

        id   : 'configuration_menu',
        title: 'Configuration',
        type : 'basic',
        icon : 'heroicons_outline:adjustments',
        link : '/employee/setting'
    },
    ];
export const defaultCandidateNavigation: FuseNavigationItem[] = [
    {
        id   : 'Profile',
        title: 'Profile',
        type : 'basic',
        icon : 'heroicons_outline:newspaper',
        link : '/candidate/profile'
    },
    {
        id   : 'Recrutement',
        title: 'Recrutement',
        type : 'basic',
        icon : 'hail',
        link : '/candidate/recrutement'
    }
];
export const defaultSystemNavigation: FuseNavigationItem[] = [
    {
        id   : 'Partenaires',
        title: 'Partenaires',
        type : 'basic',
        icon : 'heroicons_outline:newspaper',
        link : '/system/Partenaires'
    },
    // {
    //     id   : 'ListTicket',
    //     title: 'Liste des Tickets',
    //     type : 'basic',
    //     icon : 'heroicons_outline:newspaper',
    //     link : '/system/ListTicket'
    // },
    {
        id   : 'Modules',
        title: 'Modules',
        type : 'basic',
        icon : 'heroicons_outline:newspaper',
        link : '/system/Modules'
    },
    {
        id: 'Historique',
        title: 'Historique',
        type: 'basic',
        icon: 'heroicons_outline:newspaper',
        link: '/system/Historique'
    },
    {
        id: 'ConfigurationGeneral',
        title: 'ConfigurationGeneral',
        type : 'basic',
        icon : 'heroicons_outline:newspaper',
        link : '/system/ConfigurationGeneral'
    },
    {
        id: 'CRM',
        title: 'CRM',
        type : 'collapsable',
        icon : 'heroicons_outline:newspaper',
        children: [
            {
                id: 'suivi-leads',
                title: 'Suivi Leads',
                type: 'basic',
                icon: 'plagiarism',
                link : '/system/CRM/suivi-Leads'
            },
            {
                id   : 'ListSuiviCommerciaux',
                title: 'Suivi des commerciaux',
                type : 'basic',
                icon : 'heroicons_outline:chart-bar',
                link : '/system/CRM/list-suivi-commerciaux'
            },
            {
                id: 'suivi-voyageurs',
                title: 'Suivie Voyageurs',
                type: 'basic',
                icon: 'heroicons_outline:map',
                link : '/system/CRM/suivi-voyageurs'
            },
            {
                id   : 'sondage',
                title: 'sondage',
                type : 'collapsable',
                icon: 'heroicons_solid:presentation-chart-bar',
                children: [
                    {
                        id: 'add-sondage',
                        title: 'add-sondage',
                        type: 'basic',
                        icon: 'heroicons_solid:adjustments',
                        link : '/system/CRM/add-sondage'

                    },
                    {
                        id: 'calling-center',
                        title: "Agents Sondages",
                        type: 'basic',
                        icon: 'heroicons_outline:phone-outgoing',
                        link : '/system/CRM/calling-center'
                    }
                ]
            }
        ]

    },{
        id: 'Amicale',
        title: 'Amical',
        type : 'collapsable',
        icon : 'heroicons_outline:newspaper',
        children: [
            {
                id: 'liste-amicale',
                title: 'Liste des amicales',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-list',
                link : '/system/amicales/liste-amicales'
            },
            {
                id: 'ValidationAmicale',
                title: 'Validation des amicales',
                type: 'basic',
                icon: 'heroicons_outline:badge-check',
                link : '/system/amicales/validation-amicales'
            },
        ]},
    {
        id: 'B2C',
        title: 'B2C',
        type : 'collapsable',
        icon : 'heroicons_outline:paper-airplane',
        // link : '/system/B2C'
        children: [
            {
                id: 'hotel',
                title: 'hotel',
                type: 'basic',
                icon: 'heroicons_outline:office-building',
                link: '/system/B2C/hotel'

            },
            {
                id: 'airline',
                title: 'airline',
                type: 'basic',
                icon: 'heroicons_outline:paper-airplane',
                link: '/system/B2C/airline'
            },

        ]

    },
];


export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
// @ts-ignore
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
