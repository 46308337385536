export enum ApiPaths {
    GetAllPayslipsByPartner = 'payslip/getAllPaySlipByPartner',
    GetAllBankTransfersByPartner = 'order/getByPage',
    GetPayslipPayment = 'order/getTheLastOrderCreated',
    GetAllSalaryDepositByPartner = 'salaryDeposit/getAllSalaryDepositByPage',
    ChangeSalaryDeposit = 'salaryDeposit/updateStatus',
    AddSalaryDeposit = 'salaryDeposit/create',
    DeleteSalaryDeposit = 'salaryDeposit/delete',
    UpdateSalaryDeposit = 'salaryDeposit/update',
    GetAllBonuses = 'payroll-prime-collaborator/getAllBonuses',
    GetCollaboratorPaySlips = 'payslip/getAllPayslipsByCollab',
    DownloadPdfReceipt = `salaryDeposit/generate-reçu-report`,
    GeneratePayrollJournal = 'payslip/generate-cron-for-month',
    GeneratePaymentPayslipPdf = "order/generate-borderau",
    GeneratePayslipPdf = 'payslip/generate',
    GenerateBankTransfersPdf = "order/generateVirementList",
    GenerateSalaryDepositPdf = 'salaryDeposit/generate-pdf',
    GenerateBonusPdf = "payroll-prime-collaborator/generate-prime-pdf",
    CollaboratorLoadFile = "collaborators/loadFile",
    CheckCollaboratorFullName = "collaborators/checkFullname",
    PartnerPaySlipsList = "payslip/getAllPayslipsByAdmin",
    GetTotalPrimesAmount = 'payroll-prime-collaborator/get-total-primes-amount',
    GetTotalVariablePaie ='closing-payroll/closing-variables'
}
