export const MONTH_YEAR_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
    format: 'MM-YYYY',
};

export const YEAR_FORMATS = {
    parse: {
        dateInput: 'YYYY',
    },
    display: {
        dateInput: 'YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    },
    format: 'YYYY',
};

export const DAY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'DD MM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'DD MM YYYY',
    },
    format: 'DD-MM-YYYY',
};

export const DATE_FORMATS = {
    arabFormat: 'YYYY-MM-DD',
    frenchFormat: 'DD-MM-YYYY',
    day: 'DD',
    month: 'MM',
    year: 'YYYY',
    lowerArab:'yyyy-MM-DD',
    lowerFrench:'dd-MM-yyyy',
};

export const MONTH_YEAR_FORMATS_MM_yyyy = {
    parse: {
        dateInput: 'MM/yyyy',
    },
    display: {
        dateInput: 'MM/yyyy',
        monthYearLabel: 'MMM yyyy',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
    format: 'MM-yyyy',
};
export const DATE_FORMATS_V2 = 'YYYY/MM/DD';
export const DATE_FORMATS_V3 = 'YYYY-MM-DD';
export const DATE_FORMATS_V4 = 'YYYY-MM-dd';
export const DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_FORMAT_V4 = 'dd-MM-yyyy hh:mm';
export const DATE_FORMAT_V5 ='dd-MM-yyyy';
export const DATE_FORMAT_V6 = 'dd-MM-yyyy HH:mm';



